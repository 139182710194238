import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import Header from "../components/index/Header"
import "../components/about-us/AboutUs.css"

import image1 from "../images/rig-worker.jpg"
import image2 from "../images/hands.png"
import image3 from "../images/crane.png"

const ContactUs = () => (
  <Layout>
    <SEO
      title="About Us"
      keywords={[
        `SimpleReport`,
        `SimpleReport.ca`,
        `reporting`,
        `report`,
        `safety`,
        `OHS`,
        `OSHA`,
        `OHS-reporting`,
        `OSHA-reporting`,
        `invoices`,
        `invoicing`,
        `ERP`,
        `Occupational Health and Safety`,
        `EHS`,
        `Environmental Health and Safety`,
      ]}
    />
    <Header />
    {/* Stack the columns on mobile by making one full-width and the other half-width */}
    <Row id="firstRowAboutUs">
      <Col sm={12} lg={4} className="d-flex align-items-center pl-5">
        <Container>
          <h2 className="pl-3 pt-2">About Us</h2>
          <Col lg={10} className="aboutUsPageText">
            <p>
              We develop innovative digital solutions for occupational safety
              industry that helps companies tackle technical challenges in the
              digital age.
            </p>
            <br />
            <p>
              Simple Report was founded in 2016 in Victoria, Canada with a
              vision to automate OHS practices and improve safety compliance in
              a variety of industries.
            </p>
          </Col>
        </Container>
      </Col>
      <Col
        id="builderImage"
        className="d-none d-lg-block m-auto"
        xs={12}
        md={7}
        lg={7}
      >
        <Card.Img src={image1} />
      </Col>
    </Row>

    {/* Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop */}
    <Row id="secondRowAboutUs">
      <Col id="handsImage" className="d-none d-lg-block m-auto" sm={12} md={6}>
        <Card.Img src={image2} />
      </Col>
      <Col sm={12} lg={6} className="d-flex align-items-center pl-5">
        <Container>
          <Col lg={10} className="aboutUsPageText">
            <h5>Our Values</h5>
            <p>Core values our company was founded on:</p>
            <br />
            <p>
              <span className="listItems">-</span> Accountability
            </p>
            <p>
              <span className="listItems">-</span> Respect
            </p>
            <p>
              <span className="listItems">-</span> Ongoing innovation
            </p>
            <p>
              <span className="listItems">-</span> Trust
            </p>
            <br />
            <br />
            <p>
              Every decision and engagement is guided by these values. We are
              dedicated to upholding each values to build strong and thriving
              business.
            </p>
          </Col>
        </Container>
      </Col>
    </Row>

    {/* Columns are always 50% wide, on mobile and desktop */}
    <Row id="thirdRowAboutUs">
      <Col sm={12} lg={6} className="d-flex align-items-center pl-5">
        <Container>
          <Col lg={10} className="aboutUsPageText">
            <h5>Our Mission</h5>
            <p>
              We are dedicated to providing clients with innovative services to
              improve HSE monitoring and compliance by enabling companies to use
              digital technologies to improve operational efficiency.
            </p>
            <br />
            <p>
              Technology allow companies to automate and improve occupational
              safety by digitizing reporting services, automate data analysis,
              and bring personalized HSE training to any employee with a
              computer or mobile device.
            </p>
          </Col>
        </Container>
      </Col>
      <Col
        sm={8}
        md={6}
        className="d-none d-lg-block m-auto"
        id="bottomImageAboutUs"
      >
        <Card.Img src={image3} />
      </Col>
    </Row>
  </Layout>
)

export default ContactUs
